var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        title: _vm.title + "      " + _vm.form.dealer_name,
        visible: _vm.showDialog,
        width: "1200px",
        top: "5vh",
        "close-on-click-modal": false,
        "destroy-on-close": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "orderTest-container",
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                inline: "",
                disabled: _vm.view,
                "label-position": "right",
                "label-width": "90px",
              },
            },
            [
              _vm.status != 0
                ? _c("order-status", {
                    ref: "orderStatus",
                    attrs: { status: _vm.status },
                    on: {
                      "update:status": function ($event) {
                        _vm.status = $event
                      },
                    },
                  })
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "购货方:", prop: "cust_name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.cust_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "cust_name", $$v)
                      },
                      expression: "form.cust_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "联系人:", prop: "boss" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.boss,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "boss", $$v)
                      },
                      expression: "form.boss",
                    },
                  }),
                ],
                1
              ),
              _vm.form.ckzx_falg == 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "业务员:", prop: "boss" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: { clearable: "", placeholder: "" },
                          model: {
                            value: _vm.form.staff_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "staff_id", $$v)
                            },
                            expression: "form.staff_id",
                          },
                        },
                        _vm._l(_vm.staffList, function (i, idx) {
                          return _c("el-option", {
                            key: idx,
                            attrs: { value: i.id, label: i.name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c("div"),
              _c(
                "el-form-item",
                { attrs: { label: "电话:", prop: "mobile" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.mobile,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "mobile", $$v)
                      },
                      expression: "form.mobile",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "出货仓库:", prop: "depot_name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.depot_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "depot_name", $$v)
                      },
                      expression: "form.depot_name",
                    },
                  }),
                ],
                1
              ),
              _c("div"),
              _c(
                "el-form-item",
                { attrs: { label: "地址:", prop: "address" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "360px" },
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "address", $$v)
                      },
                      expression: "form.address",
                    },
                  }),
                ],
                1
              ),
              _c("div"),
              _c(
                "el-form-item",
                { attrs: { label: "单号:", prop: "bill_code" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.bill_code,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "bill_code", $$v)
                      },
                      expression: "form.bill_code",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "日期:", prop: "sale_time" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.sale_time,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sale_time", $$v)
                      },
                      expression: "form.sale_time",
                    },
                  }),
                ],
                1
              ),
              !_vm.view
                ? _c("el-form-item", {
                    attrs: { label: "商品条码:", prop: "" },
                  })
                : _vm._e(),
              _c(
                "el-table",
                {
                  staticStyle: { "margin-bottom": "15px" },
                  attrs: { stripe: "", data: _vm.form.goods },
                },
                [
                  _c(
                    "el-table-column",
                    { attrs: { align: "center", type: "index", width: "50" } },
                    [
                      _c(
                        "template",
                        { slot: "header" },
                        [
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                "popper-class": "custom-table-checkbox",
                                trigger: "hover",
                              },
                            },
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  model: {
                                    value: _vm.checkList,
                                    callback: function ($$v) {
                                      _vm.checkList = $$v
                                    },
                                    expression: "checkList",
                                  },
                                },
                                _vm._l(_vm.columns, function (item, index) {
                                  return _c("el-checkbox", {
                                    key: index,
                                    attrs: { label: item.label },
                                  })
                                }),
                                1
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { slot: "reference", type: "text" },
                                  slot: "reference",
                                },
                                [
                                  _c("vab-remix-icon", {
                                    attrs: { icon: "settings-line" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _vm._l(_vm.finallyColumns, function (item, tableIndex) {
                    return _c("el-table-column", {
                      key: tableIndex,
                      attrs: {
                        prop: item.prop,
                        label: item.label,
                        width: "",
                        align: "center",
                      },
                    })
                  }),
                ],
                2
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: { label: "备注:", prop: "remark" },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: { label: "结算方式:", prop: "account_type_text" },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.account_type_text,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "account_type_text", $$v)
                      },
                      expression: "form.account_type_text",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "订单金额:", prop: "total_amount" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.total_amount,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "total_amount", $$v)
                      },
                      expression: "form.total_amount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "销售金额:", prop: "sale_amount" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.sale_amount,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sale_amount", $$v)
                      },
                      expression: "form.sale_amount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "使用预存款:", prop: "deposit_amount" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.deposit_amount,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "deposit_amount", $$v)
                      },
                      expression: "form.deposit_amount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "还货金额:", prop: "return_amount" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.return_amount,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "return_amount", $$v)
                      },
                      expression: "form.return_amount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "优惠后:", prop: "discounted_amount" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.discounted_amount,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "discounted_amount", $$v)
                      },
                      expression: "form.discounted_amount",
                    },
                  }),
                ],
                1
              ),
              _c("div"),
              _c(
                "el-form-item",
                { attrs: { label: "退货金额", prop: "refund_goods_amount" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.refund_goods_amount,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "refund_goods_amount",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.refund_goods_amount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "退货方式", prop: "refund_type_text" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.refund_type_text,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "refund_type_text", $$v)
                      },
                      expression: "form.refund_type_text",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "退货退款:", prop: "refund_amount" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.refund_amount,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "refund_amount", $$v)
                      },
                      expression: "form.refund_amount",
                    },
                  }),
                ],
                1
              ),
              _vm.form.is_fun
                ? _c(
                    "el-form-item",
                    { attrs: { label: "费用抵扣", prop: "thtk" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.support_amount,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "support_amount",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.support_amount",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("div"),
              _c(
                "el-form-item",
                { attrs: { label: "应收款:", prop: "receiv_money" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.receiv_money,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "receiv_money", $$v)
                      },
                      expression: "form.receiv_money",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "实收款:", prop: "pay_amount" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.pay_amount,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "pay_amount", $$v)
                      },
                      expression: "form.pay_amount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  attrs: {
                    stripe: "",
                    data: _vm.form.arr_collect,
                    "show-summary": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "pay_type_name",
                      label: "收款方式",
                      width: "160px",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "amount",
                      label: "金额",
                      width: "160px",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "user_name",
                      label: "收款人",
                      width: "160px",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "create_at",
                      label: "时间",
                      width: "160px",
                      align: "center",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-top": "5px", "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [_c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")])],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }